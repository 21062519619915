import { BaseResponse } from '@core/models/base.model';
import { Country } from '@core/models/country.model';
import { Account } from '@main/account/account.model';
import { AdSize, AdUnit } from '@main/ad-unit/ad-unit.model';
import { Site } from '@main/site/site.model';
import { FileHandle } from '@src/app/core/directives/drag-file-handle/drag-file-handle.directive';

export enum OverlayDesignOptions {
  NORMAL = 1,
  AMP_LIKE,
  ADSENSE_LIKE,
}

export interface Currency {
  name?: string;
  code: string;
}

export interface GAMNetwork {
  id: number;
  network_id: number;
  // @ts-ignore
  network_code: string;
  display_name: string;
  gam_display_name?: string;
  property_code: string;
  currency?: string;
}

export interface GAMNetworkResponse extends BaseResponse {
  results: GAMNetwork[];
}

export enum BrowserType {
  CHROME = 1,
  SAFARI,
}

export interface ATSBrowser {
  type: BrowserType;
  major_versions: number[] | string[];
}

export enum GAMAdsRefresh {
  AllSlots = 1,
  OnlyATS,
}

export enum IframeType {
  desktop = 'desktop',
  mobile = 'mobile',
}

export enum PrebidVersion {
  Version8 = 8,
  Version9 = 9,
}

export interface SiteSetting {
  site: Partial<Site>;
  gam_network: GAMNetwork | null;
  currency: Currency | null;
  is_compiling: boolean;
  enable_adloox: boolean;
  adloox_request_ratio: number;
  enable_confirmed_click: boolean;
  confirmed_click_ratio: number;
  enable_basic_set_targetings: boolean;
  enable_collapse_empty_divs: boolean;
  enable_gam_single_request: boolean;
  enable_gam_lazy_load: boolean;
  gam_lazy_load_fetch_margin_percent: number;
  gam_lazy_load_render_margin_percent: number;
  gam_lazy_load_mobile_scaling: number;
  gam_ads_refresh: GAMAdsRefresh;
  enable_prebid: boolean;
  prebid_version: PrebidVersion;
  prebid_timeout: number;
  prebid_tam_pub_id: string;
  prebid_custom_script: string;
  enable_schain?: boolean;
  uncompiled_changes?: boolean;
  keywords_block_enabled: boolean;
  keywords: string[];
  keywords_containers: string[];
  keywords_country: Country[];
  browser_block_enabled: boolean;
  browsers: ATSBrowser[];
  enable_url_block: boolean;
  blocked_urls: string[] | string;
  render_gpt_js: boolean;
  is_spa: boolean;
  destroy_ats_slots_only: boolean;
  enable_ip_blocking: boolean;
  enable_apm: boolean;
  apm_network_id: boolean;
  apm_site_id: boolean;
  enable_separate_request: boolean;
  enable_mi: boolean;
  mi_child_gam_network_code?: string;
  enable_identity_hub: boolean;
  identity_hub_publisher_id?: string;
  identity_hub_profile_id?: string;
  enable_liveramp: boolean;
  liveramp_js_url?: string;
  liveramp_script?: string;
  note?: string;
  enable_prebid_analytics: boolean;
  analytics_sampling_rate: number;
  enable_ppid: boolean;
  ppid_server_domain: string | null;
  enable_ppid_custom_js: boolean;
  ppid_custom_js: string;
  prebid_usersync_script: string;
  enable_ad_recovery: boolean;
  // video contens setting. NOTE: using any here for special data structure on FE to validate
  channel_ids: string[] | any[];
  used_channel_ids: any[];
  // ats_video_content original_contents
  ats_video_content: OriginalContentItem[];
  uuid?: number;
  enable_custom_script: boolean;
  custom_script?: string;
  bidder_statuses?: BidderStatusPayload[];
  use_async_js_trigger?: boolean;
  async_js_trigger?: string;
  ats_site_setting_rss_feeds: SelectVideoContentSetting[];
  ats_site_setting_video_generations: ArticleSetting[];
  enable_user_id_solutions?: boolean;
  user_id_solutions?: UserIdSolutionModule[];
  enable_truvid: boolean;
  truvid_folder_id?: number;
  enable_provided_signal: boolean;
  iab_taxonomies?: {
    id: number;
    name?: string;
  }[];
  iab_taxonomies_form?: number[];
}

export interface UserIdSolutionModule {
  id?: number;
  user_id_solution: number;
  params?: any;
  storage?: any;
  value?: any;
  status: IdSolutionModuleStatus;
  storage_required?: boolean;
  params_required?: boolean;
  params_json_schema?: any;
}

export enum IdSolutionModuleStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum IdSolutionModuleStorageType {
  HTML5 = 'html5',
  COOKIE = 'cookie',
}

export interface OriginalContentItem {
  video: string | FileHandle;
  thumbnail: string | FileHandle;
  title: string;
  id?: number | null;
  is_selected: boolean;
  upload_to_truvid?: boolean;
}

export interface Device {
  id: number;
  name: string;
}

export interface DeviceResponse extends BaseResponse {
  results: Device[];
}

export enum PathSettingType {
  InterstitialAd = 1,
  SkyscraperAd,
  OverlayAd,
  InsertAd,
  AdxInterstitial,
  PrebidOnlyAd = 8,
  CustomScript,
  APM,
  Parallax,
  ReplaceAd,
  AdxOverlay,
  SliderAd,
  Instream,
}

export enum PathSettingStatus {
  Active = 1,
  Inactive,
  Archived,
}

export enum CollapseEmptyDiv {
  None = 0,
  AfterFetch,
  BeforeFetch,
}

export enum ReferrerFilteringRule {
  Include = 1,
  Exclude,
}

export enum PathType {
  Regex = 'regex',
  Contains = 'contains',
  StartsWith = 'starts_with',
  EndsWith = 'ends_with',
}

export interface PathSetting {
  id?: number;
  ad_unit?: Pick<AdUnit, 'id' | 'name' | 'gam_ad_unit_path'>;
  fixed_path?: string | null;
  device: Exclude<Device, 'name'>;
  type: PathSettingType;
  status: PathSettingStatus;
  path_type: PathType;
  path_regex: string;
  execute_order: number;
  break_point: boolean;
  enable_referrer_targeting: boolean;
  enable_keeping_referrer: boolean;
  filtering_rule: ReferrerFilteringRule;
  domain_list: string[];
}

export interface PathSettingResponse extends BaseResponse {
  results: PathSetting[];
}

export interface PathSettingsBreakPoint {
  id: number;
  execute_order?: number;
  break_point?: boolean;
  status?: PathSettingStatus;
}

export interface SiteTopATSSettingsNote {
  note: string;
}

export interface ExecuteOrderBody {
  ats_path_settings: PathSettingsBreakPoint[];
  site_setting: SiteTopATSSettingsNote;
}

export interface ATSBidder {
  id: number;
  name?: string;
  code?: BidderAutomationSpecial;
  is_active?: boolean;
  params_schema?: any;
  params_template?: any;
  enable_header_bidding_automation?: boolean;
  is_bidder_status_active?: boolean;
  enable?: boolean;
}

export interface ATSBidderResponse extends BaseResponse {
  results: ATSBidder[];
}

export interface PreBidderSetting {
  id?: number;
  ats_prebid_bidder: ATSBidder;
  enable: boolean;
  params_setting: any;
  enable_script: boolean;
  script_setting: string;
  name?: string;
  is_active?: boolean;
  bidder_status?: boolean;
  is_bidder_status_active?: boolean;
}

export enum MediaType {
  BANNER = 1,
  VIDEO,
  NATIVE,
  BANNER_VIDEO,
  BANNER_NATIVE,
  VIDEO_NATIVE,
  BANNER_VIDEO_NATIVE,
}

export interface IMedia {
  id?: number;
  type: MediaType;
  settings: any;
  prebid_bidder_settings: PreBidderSetting[];
}

export interface SizeMapping {
  id?: number;
  viewport_height: number;
  viewport_width: number;
  ad_sizes: Pick<AdSize, 'id'>[];
  media: IMedia[];
}

export interface ATSAdUnit {
  ats_path_setting: PathSetting;
  ad_unit: Pick<AdUnit, 'id' | 'name'>;
  fixed_path: string;
  ats_ad_size_mappings: SizeMapping[];
  collapse_empty_div: CollapseEmptyDiv;
  refresh_interval: number;
  in_view_rate: number;
  enable_refresh: boolean;
  refresh_limit: number;
  enable_adsense_replacement: boolean;
  adsense_replacement_client?: string;
  adsense_replacement_slot?: string;
  enable_custom_set_targetings: boolean;
  ats_custom_set_targetings: AtsCustomSetTargetings[];
  enable_gam_set_force_safe_frame_for_slot?: boolean;
  enable_multiple_calls?: boolean;
}

export interface BaseATSAdSetting {
  ats_ad_unit: ATSAdUnit;
}

export enum InterstitialAdCloseButtonPosition {
  topLeft = 1,
  topRight,
  topMiddle,
  bottomLeft,
  bottomRight,
  bottomMiddle,
}

export interface InterstitialAdSetting extends BaseATSAdSetting {
  close_button_text: string;
  close_button_position: InterstitialAdCloseButtonPosition;
  use_close_button_x_icon: boolean;
  close_button_text_color: string;
  close_button_background_color: string;
  close_button_bold_text: boolean;
  use_custom_css: boolean;
  custom_css: string;
  block_without_ads: boolean;
  script: string;
}

export enum AnchorPosition {
  Left = 1,
  Right,
  Top = 1,
  Bottom,
}

type AnchorTop = AnchorPosition.Top;
type AnchorLeft = AnchorPosition.Left;
type AnchorRight = AnchorPosition.Right;
type AnchorBottom = AnchorPosition.Bottom;
export type SkyscraperAnchorPosition = Exclude<AnchorPosition, AnchorTop | AnchorBottom>;

export enum TargetType {
  QuerySelector = 1,
  Function,
}
export enum SliderPosition {
  Left = 1,
  Right,
}

export interface SkyscraperAdSetting extends BaseATSAdSetting {
  margin: string;
  target_type: TargetType;
  target: string;
  is_sticky: boolean;
  anchor_position: SkyscraperAnchorPosition;
  show_advertisement?: boolean;
  custom_show_ads_text?: string;
  custom_css_for_show_advertisement?: string;
}

export type OverlayAnchorPosition = Exclude<AnchorPosition, AnchorLeft | AnchorRight>;

export interface OverlayAdSetting extends BaseATSAdSetting {
  background_css: string;
  anchor_position: OverlayAnchorPosition;
  function_trigger: string;
  use_function_trigger: boolean;
  custom_css: string;
  use_custom_css: boolean;
  overlay_design?: OverlayDesignOptions;
}

export enum InsertPosition {
  BeforeBegin = 1,
  AfterBegin,
  BeforeEnd,
  AfterEnd,
}

export interface InsertAdSetting extends BaseATSAdSetting {
  num_of_ads: number;
  target_type: TargetType;
  target: string;
  choices: string[];
  excluded_choices: string[];
  target_first_level_only: boolean;
  insert_position: InsertPosition;
  spacing: number;
  custom_css: string;
  custom_css_for_show_advertisement: string;
  show_advertisement: boolean;
  is_flexible: boolean;
  is_sticky: boolean;
  sticky_stop_target_type: TargetType;
  sticky_stop_target: string;
  override_margin: boolean;
  override_margin_top: number;
  override_margin_bottom: number;
  sticky_sizes: Pick<AdSize, 'id' | 'name'>[];
  enable_lazy_loading?: boolean;
  lazy_loading_mode?: LAZY_LOADING_MODE;
  lazy_loading_config?: number;
  custom_show_ads_text?: string;
}

export enum LAZY_LOADING_MODE {
  PERCENTAGE = 1,
  VIEWPORT = 2,
}

export enum ContainerWidthType {
  PERCENT = 1,
  VIEWPORT_WIDTH = 2,
}

export enum InstreamAnchorPosition {
  RIGHT = 1,
  LEFT,
}

export interface ParallaxSetting extends BaseATSAdSetting {
  target_type: TargetType;
  target: string;
  insert_position: InsertPosition;
  custom_css: string;
  margin_top: number;
  container_margin: string;
  container_height: number;
  container_width_type: ContainerWidthType;
  content_width: string;
  center_ad: boolean;
  background_color: string;
  header_text: string;
  footer_text: string;
}

export interface PrebidOnlyAdSetting extends BaseATSAdSetting {
  div_id_type: TargetType;
  div_id: string;
}

export interface ReplaceAdSetting extends BaseATSAdSetting {
  target: string;
  target_type: TargetType;
  replace_time: number;
  custom_css: string;
  show_advertisement: boolean;
  custom_show_ads_text: string;
  custom_css_for_show_advertisement: string;
  enable_lazy_loading: boolean;
  lazy_loading_mode: LAZY_LOADING_MODE;
  lazy_loading_config: number;
}

export interface AdxOverlayAdSetting extends BaseATSAdSetting {
  anchor_position: OverlayAnchorPosition;
}

export interface SliderAdSetting extends BaseATSAdSetting {
  target_type: TargetType;
  target: string;
  insert_position: InsertPosition;
  slider_position: SliderPosition;
  slider_scale: number;
  custom_css: string;
}

export type YoutubeVideoFilter = Partial<{ q: string; sort_by: string; limit: number; offset: number; search_key: string }>;

export interface SelectedYoutubeVideo {
  id?: number;
  video_id: string;
  channel_id?: string;
  title: string;
  video_length?: number;
  published_at: string;
  updated_at: string | null;
  format: string;
  thumbnail: string;

  is_selected?: boolean;
  is_hover?: boolean;
}

export enum YoutubeVideoFeedSortOptions {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  LONGEST = 'longest',
  SHORTEST = 'shortest',
}

export enum PlayerInitiationMode {
  AutoPlay = 1,
  ClickToPlay,
}

export enum AdSlotTiming {
  PreRoll = 1,
  MidRoll,
  PostRoll,
}

export enum VideoRatioOptions {
  RATIO_16_9 = 1,
  RATIO_9_16,
  RATIO_4_3,
  RATIO_3_4,
  RATIO_1_1,
}

export enum AspectRatioValues {
  RATIO_16_9 = '16:9',
  RATIO_9_16 = '9:16',
  RATIO_4_3 = '4:3',
  RATIO_3_4 = '3:4',
  RATIO_1_1 = '1:1',
}

export enum ScrollEffect {
  STATIC = 1,
  TOP_STICKY,
  ANCHORING,
}

export enum VideoSourceOptions {
  YOUTUBE = 1,
  ANYMIND,
  RSS_FEED,
  ARTICLE,
  SHORT_VIDEO,
}
export enum AdOutputFormatOptions {
  VAST = 1,
  VMAP,
}

export enum YoutubeVideoContentSettingOptions {
  LATEST_VIDEO = 1,
  SELECTED_YOUTUBE_VIDEO = 2,
}

export interface ThirdPartyVideoPlayerSetting {
  custom_css: string;
  frequency: number;
  id: number;
  is_enabled: boolean;
  slot: string;
  type: ThirdPartyVideoType;
  width: number;
}

export interface InstreamAdSetting extends BaseATSAdSetting {
  target_type: TargetType;
  target: string;
  insert_position: InsertPosition;
  aspect_ratio: VideoRatioOptions;
  max_width: number;
  max_height: number;

  custom_css: string;
  is_sound: boolean;
  initiation_mode: PlayerInitiationMode;

  scroll_effect: ScrollEffect;
  enable_ad_anchored_playback: boolean;
  anchor_position: InstreamAnchorPosition;
  video_source: VideoSourceOptions;
  channel_id: string;
  youtube_content_setting: number;
  selected_youtube_videos: SelectedYoutubeVideo[];
  selected_video_contents: any[];
  selected_rss_feeds: SelectVideoContentSetting[];
  selected_video_generation: number;
  ad_output_format: AdOutputFormatOptions;
  enable_vast_continuous_ads: boolean;
  number_of_repeated_ads: number;
  enable_multi_content: boolean;
  third_party_video_player_settings: ThirdPartyVideoPlayerSetting[];

  // insert ad setting
  slot_width: number;
  slot_height: number;
  ad_slot_timing: AdSlotTiming;

  // preview mode
  scale_down?: boolean;
  show_top_sticky_video_close_button?: boolean;
}

export type CommonAd =
  | InterstitialAdSetting
  | SkyscraperAdSetting
  | OverlayAdSetting
  | InsertAdSetting
  | PrebidOnlyAdSetting
  | ParallaxSetting
  | ReplaceAdSetting
  | SliderAdSetting
  | InstreamAdSetting
  | AdxOverlayAdSetting
  | CustomScriptAdSetting
  | ApmAdSetting;

export enum CustomScriptTemplateStatus {
  Active = 1,
  Inactive,
  Archived,
}

export interface CustomScriptTemplate {
  id: number;
  account: Pick<Account, 'id' | 'name' | 'type' | 'is_editable' | 'status'> | Pick<Account, 'id'>;
  name: string;
  status: CustomScriptTemplateStatus;
  script: string;
  style: string;
}

export interface CustomScriptTemplateResponse extends BaseResponse {
  results: CustomScriptTemplate[];
}

export interface CustomScriptAdSetting extends BaseATSAdSetting {
  ats_custom_script_template: Partial<CustomScriptTemplate>;
  script: string;
  style: string;
}

export interface ApmAdSetting extends BaseATSAdSetting {
  page_id: number;
  format_id: number;
  target_type: number;
  target: string;
  insert_position: number;
  custom_css: string;
}

export interface AtsVersion {
  id: number;
  name: string;
  created_by: {
    id: string;
    name: string;
  };
  create_date: string;
  is_current_version: boolean;
  ats_js_version: ATS_VERSION_BUILD;
  status?: ATS_BUILD_STATUS;
}

export interface AtsVersionResponse extends BaseResponse {
  results: AtsVersion[];
}

export interface AtsBuildResponse {
  status: string;
  build_success?: boolean;
  build_status?: ATS_BUILD_STATUS;
}

export interface AtsRestoreResponse {
  status: string;
  restore_success: boolean;
}

export const SITE_TOP_COLUMN_DEF = [
  'order',
  'select_item',
  'check_status',
  'break_point',
  'device',
  'status',
  'path_regex',
  'type',
  'ad_unit_path',
  'note',
];

export const MEDIA_TYPE_SETTING_PROPERTY_BANNER = {
  banner: {
    type: 'object',
    required: ['sizes'],
    properties: {
      sizes: {
        anyOf: [
          {
            type: 'array',
            items: [
              {
                type: 'number',
              },
              {
                type: 'number',
              },
            ],
            minItems: 2,
            additionalItems: {
              anyOf: [
                {
                  type: 'number',
                },
                {
                  type: 'number',
                },
              ],
            },
          },
          {
            type: 'array',
            items: {
              type: 'array',
              items: [
                {
                  type: 'number',
                },
                {
                  type: 'number',
                },
              ],
              minItems: 2,
              additionalItems: {
                anyOf: [
                  {
                    type: 'number',
                  },
                  {
                    type: 'number',
                  },
                ],
              },
            },
          },
        ],
        title: 'sizes',
      },
      name: {
        type: 'string',
        title: 'name',
      },
    },
  },
};

export const MEDIA_TYPE_SETTING_PROPERTY_VIDEO = {
  video: {
    type: 'object',
    properties: {
      context: {
        enum: ['instream', 'outstream'],
        type: 'string',
        title: 'context',
      },
      playerSize: {
        type: 'array',
        items: [
          {
            type: 'number',
          },
          {
            type: 'number',
          },
        ],
        minItems: 2,
        additionalItems: {
          anyOf: [
            {
              type: 'number',
            },
            {
              type: 'number',
            },
          ],
        },
        title: 'playerSize',
      },
      api: {
        type: 'array',
        items: {
          type: 'number',
        },
        title: 'api',
      },
      mimes: {
        type: 'array',
        items: {
          type: 'string',
        },
        title: 'mimes',
      },
      protocols: {
        type: 'array',
        items: {
          type: 'number',
        },
        title: 'protocols',
      },
      playbackmethod: {
        type: 'array',
        items: {
          type: 'number',
        },
        title: 'playbackmethod',
      },
    },
  },
};

export const MEDIA_TYPE_SETTING_PROPERTY_NATIVE = {
  native: {
    type: 'object',
    properties: {
      type: {
        type: 'string',
        title: 'type',
      },
      title: {
        type: 'object',
        properties: {
          required: {
            type: 'boolean',
            title: 'required',
          },
          len: {
            type: 'number',
            title: 'len',
          },
        },
        title: 'title',
      },
      body: {
        type: 'object',
        properties: {
          required: {
            type: 'boolean',
            title: 'required',
          },
          len: {
            type: 'number',
            title: 'len',
          },
        },
        title: 'body',
      },
      sponsoredBy: {
        type: 'object',
        properties: {
          required: {
            type: 'boolean',
            title: 'required',
          },
        },
        title: 'sponsoredBy',
      },
      icon: {
        type: 'object',
        properties: {
          required: {
            type: 'boolean',
            title: 'required',
          },
          sizes: {
            anyOf: [
              {
                type: 'array',
                items: [
                  {
                    type: 'number',
                  },
                  {
                    type: 'number',
                  },
                ],
                minItems: 2,
                additionalItems: {
                  anyOf: [
                    {
                      type: 'number',
                    },
                    {
                      type: 'number',
                    },
                  ],
                },
              },
              {
                type: 'array',
                items: {
                  type: 'array',
                  items: [
                    {
                      type: 'number',
                    },
                    {
                      type: 'number',
                    },
                  ],
                  minItems: 2,
                  additionalItems: {
                    anyOf: [
                      {
                        type: 'number',
                      },
                      {
                        type: 'number',
                      },
                    ],
                  },
                },
              },
            ],
            title: 'sizes',
          },
          aspect_ratios: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                min_width: {
                  type: 'number',
                  title: 'min_width',
                },
                ratio_height: {
                  type: 'number',
                  title: 'ratio_height',
                },
                ratio_width: {
                  type: 'number',
                  title: 'ratio_width',
                },
              },
              required: ['ratio_height', 'ratio_width'],
            },
            title: 'aspect_ratios',
          },
        },
        required: ['sizes'],
        title: 'icon',
      },
      image: {
        type: 'object',
        properties: {
          required: {
            type: 'boolean',
            title: 'required',
          },
          sizes: {
            anyOf: [
              {
                type: 'array',
                items: [
                  {
                    type: 'number',
                  },
                  {
                    type: 'number',
                  },
                ],
                minItems: 2,
                additionalItems: {
                  anyOf: [
                    {
                      type: 'number',
                    },
                    {
                      type: 'number',
                    },
                  ],
                },
              },
              {
                type: 'array',
                items: {
                  type: 'array',
                  items: [
                    {
                      type: 'number',
                    },
                    {
                      type: 'number',
                    },
                  ],
                  minItems: 2,
                  additionalItems: {
                    anyOf: [
                      {
                        type: 'number',
                      },
                      {
                        type: 'number',
                      },
                    ],
                  },
                },
              },
            ],
            title: 'sizes',
          },
          aspect_ratios: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                min_height: {
                  type: 'number',
                  title: 'min_height',
                },
                min_width: {
                  type: 'number',
                  title: 'min_width',
                },
                ratio_height: {
                  type: 'number',
                  title: 'ratio_height',
                },
                ratio_width: {
                  type: 'number',
                  title: 'ratio_width',
                },
              },
              required: ['ratio_height', 'ratio_width'],
            },
            title: 'aspect_ratios',
          },
        },
        title: 'image',
      },
      clickUrl: {
        type: 'object',
        properties: {
          required: {
            type: 'boolean',
            title: 'required',
          },
        },
        title: 'clickUrl',
      },
      cta: {
        type: 'string',
        title: 'cta',
      },
    },
  },
};

export const PREBID_MEDIA_BANNER_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['banner'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_BANNER,
  },
};

export const PREBID_MEDIA_VIDEO_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['video'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_VIDEO,
  },
};

export const PREBID_MEDIA_NATIVE_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['native'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_NATIVE,
  },
};

export const PREBID_MEDIA_BANNER_VIDEO_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['banner', 'video'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_BANNER,
    ...MEDIA_TYPE_SETTING_PROPERTY_VIDEO,
  },
};

export const PREBID_MEDIA_BANNER_NATIVE_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['banner', 'native'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_BANNER,
    ...MEDIA_TYPE_SETTING_PROPERTY_NATIVE,
  },
};

export const PREBID_MEDIA_BANNER_VIDEO_NATIVE_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['banner', 'video', 'native'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_BANNER,
    ...MEDIA_TYPE_SETTING_PROPERTY_NATIVE,
    ...MEDIA_TYPE_SETTING_PROPERTY_VIDEO,
  },
};

export const PREBID_MEDIA_VIDEO_NATIVE_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  required: ['video', 'native'],
  properties: {
    ...MEDIA_TYPE_SETTING_PROPERTY_VIDEO,
    ...MEDIA_TYPE_SETTING_PROPERTY_NATIVE,
  },
};

export const INSTREAM_VIDEO_DEFAULT_SETTINGS = {
  video: {
    context: 'instream',
    playerSize: [320, 180],
    mimes: ['video/mp4'],
    protocols: [1, 2, 3, 4, 5, 6, 7, 8],
    playbackmethod: [2],
    skip: 1,
  },
};

export const BANNER_DEFAULT_SETTINGS = {
  banner: {
    sizes: [
      [300, 250],
      [300, 600],
    ],
  },
};
export const VIDEO_DEFAULT_SETTINGS = {
  video: {
    api: [2],
    mimes: ['video/mp4', 'video/webm', 'video/3gpp', 'application/javascript'],
    plcmt: 4,
    context: 'outstream',
    linearity: 1,
    placement: 3,
    protocols: [2, 3, 5, 6, 7, 8, 11, 12, 13, 14],
    playerSize: [256, 144],
    maxduration: 30,
    minduration: 5,
    playbackmethod: [2],
  },
};
export const NATIVE_DEFAULT_SETTINGS = {
  native: {
    image: { required: true, sizes: [150, 50] },
    title: { required: true, len: 80 },
    sponsoredBy: { required: true },
    clickUrl: { required: true },
    body: { required: true },
    icon: { required: true, sizes: [50, 50] },
  },
};

export const BANNER_VIDEO_DEFAULT_SETTINGS = {
  banner: {
    sizes: [
      [300, 250],
      [300, 600],
    ],
  },
  video: VIDEO_DEFAULT_SETTINGS,
};
export const BANNER_NATIVE_DEFAULT_SETTINGS = {
  banner: {
    sizes: [
      [300, 250],
      [300, 600],
    ],
  },
  native: {
    image: { required: true, sizes: [150, 50] },
    title: { required: true, len: 80 },
    sponsoredBy: { required: true },
    clickUrl: { required: true },
    body: { required: true },
    icon: { required: true, sizes: [50, 50] },
  },
};
export const VIDEO_NATIVE_DEFAULT_SETTINGS = {
  video: VIDEO_DEFAULT_SETTINGS,
  native: {
    image: { required: true, sizes: [150, 50] },
    title: { required: true, len: 80 },
    sponsoredBy: { required: true },
    clickUrl: { required: true },
    body: { required: true },
    icon: { required: true, sizes: [50, 50] },
  },
};

export const BANNER_VIDEO_NATIVE_DEFAULT_SETTINGS = {
  banner: {
    sizes: [
      [300, 250],
      [300, 600],
    ],
  },
  video: VIDEO_DEFAULT_SETTINGS,
  native: {
    image: { required: true, sizes: [150, 50] },
    title: { required: true, len: 80 },
    sponsoredBy: { required: true },
    clickUrl: { required: true },
    body: { required: true },
    icon: { required: true, sizes: [50, 50] },
  },
};

export interface BidderAdUnit {
  id?: number;
  bidder_site: {
    id: number;
    name: string;
    raw_data?: any;
  };
  raw_data: {
    content_category_ids?: string[];
    size_ids?: number[];
    transparency?: string;
    access?: string;
    timeout?: number;
    dynamic_ad_tag?: number;
    primary_size?: string;
    size_id?: number;
    publisher_id?: number;
    ad_format?: HBAdFormat;
    creative_type?: HBCreativeType;
    category?: string;
    download_url?: string;
    type?: TypeSiteHB;
    primary_iab?: string;
    domain_name?: string;
    page_group_id?: number;
    dimension?: string;
    dimensions?: BidderDimensionCustom[];
    creative_types?: string[];
    video_type?: string;
    video_settings?: {
      apiFramework: string;
      companionAdType: string;
      companionAdTypes: string[];
      companionAdDimension: string;
      skippable: boolean;
      companionAdCustomWidth?: number;
      companionAdCustomHeight?: number;
    };
    position?: string;
    advert_type?: HBAdvertType;
    bundle?: string;
    code?: string;
    video_sizes?: RubiconVideoSizeConfig[];
    minDuration?: number;
    maxDuration?: number;
    videoMimeTypes?: number[];
    format?: string;
    url?: string;
  };
  name: string;
  params?: any;
}

export interface BidderDimensionCustom {
  width: number;
  height: number;
}

export enum BidderAutomationSpecial {
  RUBICON = 'rubicon',
  IX = 'ix',
  OPENX = 'openx',
  APP_NEXUS = 'appnexus',
  CRITEO = 'criteo',
  PUBMATIC = 'pubmatic',
  SMAATO = 'smaato',
  SMART_AD_SERVER = 'smartadserver',
  UNICORN = 'unicorn',
  OGURY = 'ogury',
  DISCOVERY_DSP = 'discovery',
  ONE_TAG = 'onetag',
  ADAGIO = 'adagio',
  XANDR_TAM = 'xandr_tam',
  VK = 'mytarget',
}

export enum BidderAdUnitTransparency {
  TRANSPARENT = 'T',
  SEMI_TRANSPARENT = 'S',
}

export enum BidderAdUnitAccess {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum BidderAdUnitDynamicAdTag {
  NOT_ENABLED = 0,
  ENABLED,
}

export enum ATS_VERSION_BUILD {
  STABLE = '2',
  LATEST = '3',
}

export interface AtsCustomSetTargetings {
  id?: number;
  key: string;
  value: string;
  is_active: boolean;
}

export interface BidderSite {
  id?: number;
  bidder: {
    id: number;
  };
  raw_data?: {
    main_domain?: string;
    site_tag_category?: number;
    publisher_id?: number;
    category?: string;
    download_url?: string;
    type?: TypeSiteHB;
    primary_iab?: string;
    domain_name?: string;
    page_group_id?: number;
    bundle?: string;
    code?: string;
    video_sizes?: RubiconVideoSizeConfig[];
  };
  name: string;
  params?: any;
  external_id?: string;
}

export interface CompareVersion {
  source_version_data: any;
  target_version_data: any;
}

export enum HBAdFormat {
  STANDARD = 'STANDARD',
  VIDEO = 'VIDEO',
  NATIVE = 'NATIVE',
  MULTI_AD = 'MULTI_AD',
}

export enum HBCreativeType {
  DISPLAY = 'DISPLAY',
  VIDEO = 'VIDEO',
  DISPLAY_AND_VIDEO = 'DISPLAY_AND_VIDEO',
  NATIVE_IMAGE = 'NATIVE_IMAGE',
  NATIVE_RICH_MEDIA = 'NATIVE_RICH_MEDIA',
  NATIVE_VIDEO = 'NATIVE_VIDEO',
  PLAYABLE = 'PLAYABLE',
}

export enum HBDimension {
  BANNER_PHONE = 'BANNER_PHONE',
  BANNER_TABLET = 'BANNER_TABLET',
  MEDIUM_RECTANGLE = 'MEDIUM_RECTANGLE',
  FULLSCREEN_PORTRAIT = 'FULLSCREEN_PORTRAIT',
  FULLSCREEN_LANDSCAPE = 'FULLSCREEN_LANDSCAPE',
  TABLET_FULLSCREEN_PORTRAIT = 'TABLET_FULLSCREEN_PORTRAIT',
  TABLET_FULLSCREEN_LANDSCAPE = 'TABLET_FULLSCREEN_LANDSCAPE',
  CUSTOM = 'CUSTOM',
}

export enum HBVideoType {
  INSTREAM_PREROLL = 'INSTREAM_PREROLL',
  INSTREAM_MIDROLL = 'INSTREAM_MIDROLL',
  INSTREAM_POSTROLL = 'INSTREAM_POSTROLL',
  OUTSTREAM = 'OUTSTREAM',
  INTERSTITIAL = 'INTERSTITIAL',
  REWARDED = 'REWARDED',
}

export enum HBPosition {
  UNKNOWN = 'UNKNOWN',
  ABOVE_FOLD = 'ABOVE_FOLD',
  BELOW_FOLD = 'BELOW_FOLD',
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  SIDEBAR = 'SIDEBAR',
  FULLSCREEN = 'FULLSCREEN',
}

export enum HBAPIFramework {
  NONE = 'NONE',
  VPAID20 = 'VPAID20',
}

export enum HBCompanionAdType {
  ALL = 'ALL',
  NONE = 'NONE',
  STATIC = 'STATIC',
  HTML = 'HTML',
  IFRAME = 'IFRAME',
}

export enum TypeSiteHB {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  BLACKBERRY = 'BLACKBERRY',
  WINDOWS_PHONE = 'WINDOWS_PHONE',
  MOBILE_SITE = 'MOBILE_SITE',
}

export enum HBAdvertType {
  INTERSTITIAL = 'interstitial',
  OPTIN_VIDEO = 'optin_video',
  OVERLAY_THUMBNAIL = 'overlay_thumbnail',
  MEDIUM_RECTANGLE = 'medium_rectangle',
  BANNER_320x50 = 'banner_320x50',
  HEADER_AD = 'header_ad',
  IN_ARTICLE = 'in_article',
  FOOTER = 'footer_ad',
}

export interface PageGroup {
  id?: number;
  name: string;
  bidder_site: {
    id: number;
  };
  bidder_ad_sizes: FormatPage[];
}

export interface FormatPage {
  id: number;
  name: string;
}

export enum ATS_BUILD_STATUS {
  SUCCESS = 1,
  FAILED = 2,
  IN_PROCESS = 3,
}

export const JS_PREBID_DEFAULT_SCRIPT = `(function () {
  const params = {};
  return params;
})();`;

export const DISCOVERY_DSP_BANNER_TOKEN = '5b9138cccb2a14ee4ca836820c6f937e';
export const DISCOVERY_DSP_NATIVE_TOKEN = 'd629c0c58e943a4536b8b3611ab62d77';

export interface VideoAdsDataPreview {
  siteID: number;
  settingID: number;
  adType: PathSettingType;
  data: Partial<CommonAd>;
}

export const MOBILE_DEVICE_IDS = [2, 3];

export interface PrebidBidderSettingParams {
  limit?: number;
  offset?: number;
  q?: string;
  order_by?: string;
  bidder_status?: {
    site_pk?: number;
    enable?: boolean;
  };
  account_id?: number;
}

export interface BidderStatusPayload {
  enable: boolean;
  prebid_bidder: {
    id: number;
    name?: string;
    code?: string;
    enable_header_bidding_automation?: boolean;
    is_active?: boolean;
  };
}

export interface ArticleSetting {
  id?: number;
  url: string;
  icon: string;
  articles_css_selector: string;
  button_text: string;
  is_selected?: boolean;
  is_background_logo_enabled?: boolean;
  is_rendered_by_js?: boolean;
  upload_to_truvid?: boolean;
}

export interface SelectVideoContentSetting {
  id?: number;
  url: string;
  icon: string;
  is_selected: boolean;
  button_text: string;
  is_background_logo_enabled: boolean;
  upload_to_truvid?: boolean;
  // short video
  name?: string;
  articles_css_selector?: string;
  is_rendered_by_js?: boolean;
  type?: string;
  is_rss_feed?: boolean;
}

export interface UserIdModuleConfig {
  bid_request_user_id: string;
  display_name: string;
  id: number;
  name: string;
  params_json_schema: any;
  params_required: boolean;
  storage_required: boolean;
}

export interface RssFeedSettingCreatePayload extends Omit<SelectVideoContentSetting, 'id' | 'icon' | 'is_selected'> {
  icon: File;
  site_setting: {
    id: number;
  };
}

export interface RssFeedSettingUpdatePayload extends RssFeedSettingCreatePayload {
  id: number;
}

export interface UserIdModuleConfigResponse extends BaseResponse {
  results: UserIdModuleConfig[];
}

export const STORAGE_JSON_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    name: { type: 'string' },
    type: { type: 'string', enum: ['html5', 'cookie'] },
    expires: { type: 'integer', default: 90 },
    refreshInSeconds: { type: 'integer', default: 8 * 3600 },
  },
  required: ['name', 'type'],
};

export enum ThirdPartyVideoType {
  AM_VIDEO = 1,
  TRUVID_VIDEO = 2,
  GLIACLOUD_VIDEO = 3,
  // TODO: need confirm
  SELECT_MEDIA = 4,
  VDO_AI = 5
}

export interface ThirdPartyVideoPlayerSetting {
  custom_css: string;
  frequency: number;
  id: number;
  is_enabled: boolean;
  slot: string;
  type: ThirdPartyVideoType;
  width: number;
}

export interface RubiconVideoSizeConfig {
  id: number;
  name?: string;
  protocols: VASTProtocols[];
  maxDuration: number;
  apiFrameworks: RubiconApiFrameworks[];
  playbackMethods: PlaybackMethods[];
  mediaFileFormats: MimesMediaFileFormats[];
}

export enum VASTProtocols {
  VAST_2_0 = 'vast-2.0',
  VAST_2_0_WRAPPER = 'vast-2.0-wrapper',
  VAST_3_0 = 'vast-3.0',
  VAST_3_0_WRAPPER = 'vast-3.0-wrapper',
  VAST_4_0 = 'vast-4.0',
  VAST_4_0_WRAPPER = 'vast-4.0-wrapper',
  VAST_4_1 = 'vast-4.1',
  VAST_4_1_WRAPPER = 'vast-4.1-wrapper',
  VAST_4_2 = 'vast-4.2',
  VAST_4_2_WRAPPER = 'vast-4.2-wrapper',
}

export enum MimesMediaFileFormats {
  VIDEO_MP4 = 'video/mp4',
  VIDEO_WEBM = 'video/webm',
  VIDEO_3GPP = 'video/3gpp',
}

export enum RubiconApiFrameworks {
  VPAID_2_0_JS = 'vpaid-2.0-js',
  OMID_1_0 = 'omid-1.0',
  SIMID_1_0 = 'simid-1.0',
  SIMID_1_1 = 'simid-1.1',
}

export enum PlaybackMethods {
  PAGE_LOAD_SOUND_ON = 'PageLoadSoundOn',
  PAGE_LOAD_SOUND_OFF = 'PageLoadSoundOff',
  CLICK_WITH_SOUND_ON = 'ClickWithSoundOn',
  MOUSE_OVER_WITH_SOUND_ON = 'MouseOverWithSoundOn',
  ENTERING_VIEWPORT_SOUND_ON = 'EnteringViewportSoundOn',
  ENTERING_VIEWPORT_SOUND_OFF = 'EnteringViewportSoundOff',
}

export interface BulkUpdateChangePathRegex {
  path_type: PathType;
  path_regex: string;
  ats_path_settings: { id: number }[];
}

export interface ChangePathRegexDialogConfig {
  selectedItems: PathSetting[];
  siteId: number;
}
